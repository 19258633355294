import { Injectable } from '@angular/core';
import { MortgageInterface } from './mortgage';

@Injectable({
  providedIn: 'root',
})
export class MortgageService {
  public mortgage: MortgageInterface;

  constructor() {}

  calculate() {
    this.mortgage.deposit_percent = Math.round((this.mortgage.down_payment / this.mortgage.total_price) * 100);
    this.mortgage.principal =
      (this.mortgage.total_price - this.mortgage.down_payment) /
      this.mortgage.loan_period;
    this.mortgage.principal_monthly = this.mortgage.principal / 12;
    this.mortgage.interest =
      (this.mortgage.principal / 100) * this.mortgage.interest_rate;
    this.mortgage.interest_monthly = this.mortgage.interest / 12;
    this.mortgage.total_loan =
      (this.mortgage.principal + this.mortgage.interest) *
      this.mortgage.loan_period;
  }
}
