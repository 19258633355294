import { Component } from '@angular/core';
import { MortgageInterface } from './mortgage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title:string = 'Mortgage';
  mortgage: MortgageInterface;

  getMortgage(mortgage: MortgageInterface) {
    this.mortgage = mortgage;
  }
}
