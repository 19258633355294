<h1>{{ title }}</h1>
<h5>Calculate and view the monthly mortgage on this apartment</h5>
<form>
  <div class="form-group">
    <label>Total Price</label>
    <div class="row">
      <div class="col-sm-6">
        <div class="input-group">
          <input name="total-price" type="number" class="form-control" [(ngModel)]="iMortgage.total_price" (change)="calculateMortgage()" />
          <span class="input-group-text">US$</span>
        </div>
      </div>
      <div class="col-sm-6">
        <input name="total-price-slider" type="range" min="1" max="10000000" [(ngModel)]="iMortgage.total_price" class="w-100" (change)="calculateMortgage()"/>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label>Loan Period</label>
    <div class="row">
      <div class="col-sm-6">
        <div class="input-group">
          <input name="loan_period" type="number" class="form-control" [(ngModel)]="iMortgage.loan_period" (change)="calculateMortgage()"/>
          <span class="input-group-text">Years</span>
        </div>
      </div>
      <div class="col-sm-6">
        <input name="loan-period-slider" type="range" min="1" max="50" [(ngModel)]="iMortgage.loan_period" class="w-100" (change)="calculateMortgage()"/>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label>Down Payment</label>
    <div class="row">
      <div class="col-sm-6">
        <div class="input-group">
          <input name="down-payment" type="number" class="form-control" [(ngModel)]="iMortgage.down_payment" (change)="calculateMortgage()"/>
          <span class="input-group-text">{{iMortgage.deposit_percent}}%</span>
        </div>
      </div>
      <div class="col-sm-6">
        <input name="down-payment-slider" type="range" min="1" max="5000000" [(ngModel)]="iMortgage.down_payment" class="w-100" (change)="calculateMortgage()"/>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label>Interest Rate</label>
    <div class="row">
      <div class="col-sm-6">
        <div class="input-group">
          <input name="interest-rate" type="number" class="form-control" [(ngModel)]="iMortgage.interest_rate" (change)="calculateMortgage()"/>
          <span class="input-group-text">%</span>
        </div>
      </div>
      <div class="col-sm-6">
        <input name="interest-rate-slider" type="range" min="1" step="0.5" max="100" [(ngModel)]="iMortgage.interest_rate" class="w-100" (change)="calculateMortgage()"/>
      </div>
    </div>
  </div>
</form>
