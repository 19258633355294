import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { ChartType } from 'chart.js';
import { MultiDataSet, Label } from 'ng2-charts';
import { MortgageInterface } from '../../mortgage';


@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
})
export class GraphComponent implements OnInit, OnChanges {
  @Input() mortgage: MortgageInterface;

  // Doughnut
  public doughnutChartLabels: Label[] = ['Principal', 'Intrest'];
  public doughnutChartData: MultiDataSet = [[350, 450]];
  public doughnutChartType: ChartType = 'doughnut';

  constructor() { }

  setMortgage() {
    this.doughnutChartData = [
      [Math.round(this.mortgage.principal), Math.round(this.mortgage.interest)],
    ];
  }
  ngOnInit() {
    if (this.mortgage) {
      this.setMortgage();
    }
  }
  ngOnChanges() {
    if (this.mortgage) {
      this.setMortgage();
    }

  }
}
