<div class="justify-center">
  <h4 class="text-uppercase text-center">Total Loan Amount</h4>
  <h6 class="text-center">US$</h6>
  <p class="text-center">{{ mortgage.total_loan | currency }}</p>
  <hr />
  <h3 class="text-center">Payment Breakdown</h3>
  <div style="display: block">
    <canvas
      baseChart
      [data]="doughnutChartData"
      [labels]="doughnutChartLabels"
      [chartType]="doughnutChartType"

    >
    </canvas>
    <a href="javascript:void(0)" (click)="setMortgage()" title="Click to refresh" class="text-right">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-arrow-clockwise"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
        />
        <path
          d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"
        />
      </svg>
    </a>
  </div>
  <h4>Monthly</h4>
  <p>Principal: {{ mortgage.principal_monthly | currency }}</p>
  <p>Interest: {{ mortgage.interest_monthly | currency }}</p>
</div>
