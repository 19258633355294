import { Component, Input, Output, OnInit, EventEmitter, Inject } from '@angular/core';
import { MortgageService } from '../../mortgage.service';
import { MortgageInterface } from '../../mortgage';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  providers: [MortgageService]
})
export class FormComponent implements OnInit {
  @Input() title: string;
  @Output() sendMortgage = new EventEmitter<MortgageInterface>();
  public iMortgage: MortgageInterface;

  constructor(@Inject(MortgageService) private mortgageService) {
    this.iMortgage = {
      total_price: 1450000,
      loan_period: 4,
      down_payment: 120000,
      interest_rate: 12.5
    };
  }

  calculateMortgage() {
    this.mortgageService.mortgage = this.iMortgage;
    this.mortgageService.calculate();
    this.sendMortgage.emit(this.iMortgage);
  }

  ngOnInit() {
    this.calculateMortgage();
  }
}
