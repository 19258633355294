<div class="container">
  <div class="row mt-4">
    <div class="col-md-9">
      <app-form [title]="title" (sendMortgage)="getMortgage($event)"></app-form>
    </div>
    <div class="col-md-3">
      <app-graph
        [mortgage]="mortgage"
        (ngModelChange)="mortgage"
      ></app-graph>
    </div>
  </div>
</div>
